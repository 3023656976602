:root {

  /* Font families */
  --ff_main: Comfortaa;

  /* Font sizes */
  --fs_s: 12px;
  --fs_m: 14px;
  --fs_l: 16px;
  --fs_xl: 18px;
  --fs_xxl: 20px;
  --fs_xxxl: 22px;

  /* Colors */
  --c_bg: #FF7A00;
  --c_letters: #f1f1f1;
  --c_negative: red;
  --c_positive: limegreen;

  /* Transitions and animations */
  --tr_d: 0.25s;

  /* Distance of elements */
  --el_space: 20px;
  --el_space_small: 10px;

  /* Borders */
  --brdr_rds: 10px;
  
  /* Widths & sizes */
  --max_width_s: calc(80% - var(--el_space));
  --max_width_m: calc(90% - var(--el_space));
  --max_width_l: calc(100% - var(--el_space));

}

/* Set basic properties on desktop */
@media (min-width: 768px) {
  :root {
    --fs_s: 14px;
    --fs_m: 16px;
    --fs_l: 18px;
    --fs_xl: 20px;
    --fs_xxl: 22px;
    --fs_xxxl: 24px;
    --el_space: 40px;
    --el_space_small: 20px;
    --brdr_rds: 10px;
  }
}



/* Font family global */
@font-face {
  font-family: Comfortaa;
  src: url('Comfortaa.ttf');
}

body, html {
  padding: 0;
  margin: 0;
}

* {
  color: var(--c_letters);
  letter-spacing: 1.15px;
  line-height: 1.2;
  box-sizing: border-box;
  font-family: var(--ff_main);
  font-size: var(--fs_m);
  border-radius: var(--brdr_rds);
}

u {
  font-weight: bold;
}

img {
  position: relative;
  width: 100%;
  max-width: 450px;
}

svg {
  border-radius: 0;
}

input,
.button,
.list-item {
  border-radius: var(--brdr_rds); /* Border radius */
}

.fixed_down {
  position: fixed;
  bottom: var(--el_space);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

#root,
.screen {
  background: var(--c_bg);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}

/* 
* Elements container
* Full width and height, fit the screen 
*/
#root {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  max-width: 100vw;
  max-height: 100vh;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: var(--el_space);
}

/* Titles */
h2, h3 {
  text-align: center;
  margin: 0;
}
.section-title {
  font-size: var(--fs_xxxl);
}

/* Alignment */
.center {
  text-align: center;
  justify-content: center;
  align-items: center;
}

/* Header */
header {
  background: var(--c_bg);
  display: flex;
  align-items: center;
  padding-inline: 10px;
  padding-block: 10px;
  gap: var(--el_space);
}
.loading-screen header {
  flex-direction: column;
}
.loading-screen-inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}
.loading-screen-inner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.4;
  border-radius: 0;
}
.loading-screen-inner svg {
  z-index: 10;
}
.app_logo {
  display: flex;
}
.app_logo img {
  border-radius: 0!important;
  width: 64px;
  height: auto;
}
header h1 {
  font-size: var(--fs_xxl);
  margin: 0;
}

.topbar header {
  flex-direction: row;
  background: transparent;
}
.topbar header h1 {
  color: var(--c_bg);
  font-size: var(--fs_l);
}
.topbar header svg {
  width: 46px;
  height: auto;
}
.topbar header svg path {
  fill: var(--c_bg) !important;
}

/* Screens */
.screen {
  flex-direction: column;
  gap: var(--el_space);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 110;
}
.screen-inner {
  width: 100%;
  padding: var(--el_space);
  display: flex;
  flex-direction: column;
}
.gap-normal {
  gap: var(--el_space);
}
.gap-small {
  gap: var(--el_space_small);
}
.fixed_top {
  position: fixed;
  background: var(--c_bg);
  top: 0;
  left: 0;
  padding: var(--el_space);
  width: 100%;
  z-index: 1000;
}
.no-padding-block {
  padding-block: 0;
}
.back {
  z-index: 50;
}
.scrollable {
  overflow-y: auto;
}
.content_top {
  justify-content: flex-start;
}
.content_left {
  align-items: flex-start;
}

.friends-container {
flex-direction: column;
}

.topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.topbar img {
  width: 46px;
  height: 46px;
}
.topbar_user_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--el_space);
  background: var(--c_letters);
  color: var(--c_bg);
  padding: var(--el_space_small) var(--el_space_small);
  border-radius: 0;
}

#searchbar-form {
  width: 100%;
}
#searchbar-form label {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--el_space_small);
}
#searchbar-form input::placeholder {
  color: white;
}
.search-results-section {
  width: 100%;
}
.search-results-section-title {
  margin-top: 0;
  margin-bottom: var(--el_space_small);
  position: relative;
  border-radius: 0;
}
.search-results-section-title span {
  position: relative;
  padding-right: var(--el_space_small);
  z-index: 10;
  background: var(--c_bg);
  font-size: var(--fs_xl);
  font-weight: 900;
}
.search-results-section-title::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 2px;
  background: white;
}
.search-results-find-more {
  font-size: var(--fs_l);
  background: var(--c_letters);
  color: var(--c_bg);
  display: flex;
  align-items: center;
  border: 2px solid var(--c_letters);
  transition: var(--tr_d);
  gap: 5px;
  padding: 5px 10px;
}
.search-results-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--el_space);
}

.navigation {
  border-radius: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background: var(--c_letters);
  display: flex;
  gap: var(--el_space);
  align-items: center;
  justify-content: space-between;
  padding: var(--el_space_small);
}
.navigation-button {
  display: flex;
  border: none;
  background: transparent;
  padding: 5px;
}
.navigation-button svg {
  width: 26px;
  height: 26px;
}
.navigation-button svg path {
  fill: var(--c_bg);
}
.navigation-button.active {
  background: var(--c_bg);
}
.navigation-button.active svg path {
  fill: var(--c_letters);
}
/* .logout {
  margin-left: auto;
} */

.navigation,
.topbar {
  z-index: 100;
}

.map_container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
}
.map_container > div {
  width: 100%;
  height: 100%;
}
.leaflet-control-container {
  position: fixed;
  bottom: calc(64px + var(--el_space_small));
  right: var(--el_space_small);
  z-index: 1000;
}
.leaflet-top, .leaflet-bottom {
  position: relative;
  top: 0;
  left: 0;
}
.leaflet-control {
  border: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: var(--el_space_small);
}
.leaflet-control > a {
  background: var(--c_bg);
  border-radius: var(--brdr_rds)  !important;
  border: none;
}
.leaflet-control > a span {
  color: var(--c_letters);
  font-size: var(--fs_xxl);
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leaflet-control-attribution {
  display: none;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
}
.map_container .leaflet-tile-container img {
  border-radius: 0!important;
}

.user-map-controls {
  display: flex;
  flex-direction: column;
  gap: var(--el_space_small);
  position: fixed;
  left: var(--el_space_small);
  bottom: calc(64px + var(--el_space_small));
  z-index: 400;
}
.map-button {
  padding: 5px;
  background: var(--c_bg);
  border: 2px solid var(--c_bg);
  transition: var(--tr_d);
  display: flex;
  position: relative;
}
.map-button svg {
  width: 26px;
  height: 26px;
}
.map-button svg circle,
.map-button svg path {
  stroke: var(--c_letters);
}
.map-button.active {
  background: limegreen;
  border-color: limegreen;
}
.map-button.active::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  border-radius: var(--brdr_rds);
  animation-name: activeButton;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.recenter-map.pending {
  background: red;
  border-color: red;
}
.recenter-map.loading {
  animation-name: loadingLocation;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 2s;
}
@keyframes loadingLocation {
  0% {
    background-color: red;
    border-color: red;
  }
  25% {
    background-color: var(--c_bg);
    border-color: var(--c_bg);
  }
  50% {
    background-color: var(--c_bg);
    border-color: var(--c_bg);
  }
  100% {
    background-color: red;
    border-color: red;
  }
}
@keyframes activeButton {
  0% {
    box-shadow: 0 0 0 0px rgba(50, 205, 50, 0);
  }
  50% {
    box-shadow: 0 0 0 7px rgba(50, 205, 50, 0.6);
  }
  100% {
    box-shadow: 0 0 0 0px rgba(50, 205, 50, 0);
  }
}

.leaflet-control-container {
  display: none;
}

.leaflet-popup-content {
  text-align: center;
  max-width: 126px;
}
.leaflet-popup-content div {
  color: black;
}
.leaflet-popup-content > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.leaflet-popup-content img {
  width: 64px;
  height: 64px;
  margin-inline: auto;
}
.leaflet-popup-tip {
  border-radius: 0;
}
.leaflet-marker-icon {
  /* overflow: hidden; */
  border: 3px solid;
  border-radius: 50%;
}
.leaflet-marker-icon img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}
.marker-image {
  position:  relative;
  height: 100%;
  width: 100%;
}
.marker-image::after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(0);
  /* border-bottom: 20px solid black; */
  height: 0;
  width: 3px;
  height: 0;
  width: 0;
  border: 10px solid transparent!important;
  border-top: 20px solid!important;
  border-radius: 50px;
}
.marker-image-placeholder {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
}
.personal-marker .marker-image::after {
  border-top-color: var(--c_bg)  !important;
}
.personal-marker {
  border-color: var(--c_bg);
}
.friend-marker .marker-image::after {
  border-top-color:  yellow!important;
}
.friend-marker {
  border-color: yellow;
}
.place-marker .marker-image::after {
  border-top-color:  limegreen!important;
}
.place-marker {
  border-color: limegreen;
}

.user-marker-nickame {
  font-size: 10px;
}

.screen_sections_buttons {
  display: flex;
  gap: var(--el_space);
}
.screen_sections_buttons button {
  background: var(--c_letters);
  color: var(--c_bg);
  padding: 10px 15px;
  border: 2px solid var(--c_letters);
  width: 100%;
}
.screen_sections_buttons button.active {
  background: var(--c_bg);
  color: var(--c_letters);
  pointer-events: none;
}

.popup {
  z-index: 1000;
  background: white;
  padding: var(--el_space);
}
.popup h2 {
  font-size: var(--fs_m);
  color: black;
  font-weight: bold;
  margin-bottom: var(--el_space);
}
.popup h3 {
  font-size: var(--fs_s);
  color: black;
}
.popup .button {
  padding: var(--el_space_small);
  font-size: var(--fs_s);
}
.popup .list_item_actions {
  justify-content: center;
  margin-top: var(--el_space);
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.4;
  z-index: 100;
}

/* Lists */
.items_list {
  display: flex;
  flex-direction: column;
  gap: var(--el_space);
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  border-radius: 0;
}
.list_item {
  display: flex;
  gap: 10px;
  cursor: pointer;
}
.list_item img {
  width: 46px;
}
.list_item_actions {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: auto;
}
.no_results_found {
  font-size: var(--fs_l);
  text-align: center;
}

/* Forms */
.user_form_container,
.user_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--el_space);
  width: 100%;
}
.user_form {
  width: 100%;
  max-width: 400px;
}
.user_form label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--el_space_small);
  width: 100%;
}
.user_form label span {
  text-align: center;
}

/* Inputs */
input,
textarea {
  padding: 10px;
  outline: none;
  background: transparent;
  border: 2px solid var(--c_letters);
  text-align: center;
  width: 100%;
}
textarea {
  resize: none;
  height: 100px;
}
.textarea_characters_counter {
  margin-left: auto;
  width: fit-content;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.inputs_special {
  display: flex;
  gap: 10px;
}
.inputs_special input {
  height: 55px;
  width: 45px;
  padding: 5px;
  font-size: var(--fs_xxl);
  transition: var(--tr_d);
}
.inputs_special input:focus {
  box-shadow: 0 0 7px;
}
.inputs_special input:not(:placeholder-shown) {
  background: var(--c_letters);
  color: var(--c_bg);
}
.input_with_image {
  border: 2px solid white;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-inline: auto;
}
.input_with_image input {
display: none;
}
.input_with_image svg {
  width: 46px;
  height: 46px;
}
.input_with_image svg path {
  stroke: white;
}
.hidden_input {
  display: none;
}
.input-container {
  width: 100%;
  position: relative;
}
input.password_input {
  padding-right: 46px;
  text-align: left;
}
.view-password {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
  height: 100%;
  background-color: var(--c_letters);
  width: 42px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.view-password svg {
  width: 20px;
  height: 20px;
}

.profile_image_container,
.profile_image_container_settings {
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin-inline: auto;
  border: 2px solid var(--c_letters);
  position: relative;
}
.profile_image_container img {
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.profile_image_container_settings {
  width: 100%;
  max-width: 450px;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}
.profile_image_container_settings img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}
.profile_image_container_settings svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.page-inner-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  border-radius: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Buttons */
button {
  outline: none;
  width: fit-content;
  font-size: var(--fs_xxl);
  cursor: pointer;
  font-weight: bold;
  transition: var(--tr_d);
  filter: brightness(1);
}
button:disabled,
textarea:disabled,
input:disabled {
  filter: brightness(0.85);
  pointer-events: none;
}
.button {
  text-align: center;
  cursor: pointer;
}
.button_cta {
  font-size: var(--fs_l);
  background: var(--c_letters);
  color: var(--c_bg);
  padding: 10px 30px;
  border-radius: var(--brdr_rds);
  border: none;
  text-align: center;
}
.button_secondary {
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: var(--fs_m);
  text-align: center;
}
.button_underlined {
  text-decoration: underline;
}
.close_button {
  cursor: pointer;
  position: fixed;
  z-index: 150;
  bottom: var(--el_space);
  right: var(--el_space);
  display: flex;
  width: 32px;
  height: 32px;
  background: var(--c_letters);
}
.close_button svg * {
  fill: var(--c_bg);
}
.negative {
  background: var(--c_negative);
  font-size: var(--fs_s);
}
.positive {
  background: var(--c_positive);
  font-size: var(--fs_l);
}
.processing {
  filter: brightness(0.85);
  pointer-events: none;
}
.button_with_icon {
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 7px 14px;
  width: fit-content;
}
.button_only_icon {
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: 7px;
}
.button_only_icon div {
  display: flex;
}
.button_with_icon svg,
.button_only_icon svg {
  width: 18px;
  height: 18px;
}


/* Notifications */
.message {
  font-size: var(--fs_s);
  padding: 10px 20px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0;
  width: 100%;
  z-index: 1000;
}


/* Profile */
.profile-info {
  display: flex;
  flex-direction: column;
  gap: var(--el_space_small);
  text-align: center;
}
.profile-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--el_space);
}
.profile-actions > div {
  white-space: nowrap;
}
.profile-actions > div.positive {
  margin-inline: 100%!important;
}
.user-profile-image {
  margin-inline: auto;
}
.user-name {
  font-size: var(--fs_xxl);
}
.user-bio {
  background: var(--c_letters);
  color: var(--c_bg);
  padding: 7px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Settings page */
.account-settings {
  display: flex;
  flex-direction: column;
  gap: var(--el_space);
}
.account-settings-section {
  position: relative;
}
.account-settings .elements-container {
  position: relative;
}
.account-settings h3 {
  margin-bottom: var(--el_space_small);
}
.account-settings .user-options {
  position: absolute;
  top: var(--el_space_small);
  left: var(--el_space_small);
}
.user-action-icon {
  width: 24px;
  height: 24px;
  display: flex;
}
.account-settings .user-options svg {
  width: 100%;
  height: 100%;
}
.account-settings .user-options svg path {
  fill: white;
}
.user-options.horizontal {
  top: -5px;
  left: 0;
  display: flex;
  gap: 5px;
}

.bottom-spacer {
  height: 32px;
}
.spacer {
  height: var(--el_space_small);
}

/* Responsive */
@media (max-width: 500px) {
  .button_cta {
    width: 100%;
  }
}